export const App = {
  AUTH: '/auth',
  LANDING: '/landing',
  ONBOARDING: '/onboarding',
  ACCEPT_TERMS: '/terms-confirmation',
  TRY_AGAIN: '/try-again',
  VERIFY_EMAIL: '/verify-email',
  APP_REDIRECT: '/app-redirect',
  HEALTHCHECK: '/healthcheck',
  EMBED: '/embed',
  EMBED_LANDING: '/embed-landing',
  MOVE_IN: '/deposit',
}

export const Auth = {
  PHONE_CREATE_ACCOUNT: `${App.AUTH}/create-account`,
  PHONE: `${App.AUTH}/phone`,
  PIN: `${App.AUTH}/pin`,
  DEV: `${App.AUTH}/dev`,
}

export const Onboarding = {
  CREATE_ACCOUNT: `${App.ONBOARDING}/account`,
  CONFIRM_ACCOUNT: `${App.ONBOARDING}/confirm-account`,
  CREDIT_BUILDER_VALUE_PROP: `${App.ONBOARDING}/why-credit-builder`,
  RESUME_ONBOARDING: `${App.ONBOARDING}/resume-onboarding`,
  ADDRESS: `${App.ONBOARDING}/address`,
  PROPERTY: `${App.ONBOARDING}/property`,
  PORTAL_ADDRESS_ENTRY: `${App.ONBOARDING}/portal-address`,
  RENT_PORTAL_DETAILS: `${App.ONBOARDING}/portal-details`,
  RENT_PORTAL_SELECTION: `${App.ONBOARDING}/portal-selection`,
  P2P_WAITLIST: `${App.ONBOARDING}/p2p-waitlist`,
  RENT_PORTAL_SIGNIN: `${App.ONBOARDING}/portal-signin`,
  FLEX_ANYWHERE_WHERE_YOU_PAY: `${App.ONBOARDING}/flex-anywhere-where-you-pay`,
  FLEX_ANYWHERE_HOW_YOU_PAY: `${App.ONBOARDING}/flex-anywhere-how-you-pay`,
  FLEX_ANYWHERE_WAITLIST: `${App.ONBOARDING}/flex-anywhere-waitlist`,
  FLEX_ANYWHERE_HOW_FLEX_WORKS: `${App.ONBOARDING}/flex-anywhere-how-flex-works`,
  DIRECT_INTEGRATION_UNIT: `${App.ONBOARDING}/direct-integration-unit`,
  DIRECT_INTEGRATION_CONFIRMATION: `${App.ONBOARDING}/direct-integration-confirm`,
  RENT_AMOUNT: `${App.ONBOARDING}/rent-amount`,
  CARD_STAGE_SELECTION: `${App.ONBOARDING}/card-stage-selection`,
  CARD_CONFIRM: `${App.ONBOARDING}/confirm-card`,
  CARD_CONNECT: `${App.ONBOARDING}/connect-card`,
  CARD_REGISTER: `${App.ONBOARDING}/register-card`,
  CARD_UPDATE_BILLING_ADDRESS: 'update-card-billing-address',
  SOFT_CREDIT_CHECK: `${App.ONBOARDING}/soft-credit-check`,
  SCHEDULE: `${App.ONBOARDING}/schedule`,
  SCHEDULE_CONFIRM_OFFER: `${App.ONBOARDING}/schedule_offer`,
  OFFER_DETAILS: `${App.ONBOARDING}/offer-details`,
  TERMS: `${App.ONBOARDING}/terms`,
  CONGRATS: `${App.ONBOARDING}/congrats`,
  CONTACT_SUPPORT: `${App.ONBOARDING}/contact-support`,
  NOT_APPROVED: `${App.ONBOARDING}/not-approved`,
  CUSTOMIZE_SCHEDULE: `${App.ONBOARDING}/customize-schedule`,
  CHOOSE_DATE: `${App.ONBOARDING}/choose-date`,
  AUTOPILOT: `${App.ONBOARDING}/autopay`,
  APPROVED: `${App.ONBOARDING}/approved`,
  HOW_FLEX_WORKS: `${App.ONBOARDING}/how-flex-works`,
  HOW_TO_PAY_FLEX_BACK: `${App.ONBOARDING}/how-to-pay-flex-back`,
  AUTOPAY_SETUP: `${App.ONBOARDING}/autopay-setup`,
  SLC_PRODUCT_SELECTION: `${App.ONBOARDING}/product-selection`,
  UPSELL_ELIGIBILITY: `${App.ONBOARDING}/product-eligibility`,
  CREDIT_BUILDER_UPSELL: `${App.ONBOARDING}/try-credit-builder`,
  CREDIT_BUILDER_UPSELL_NOT_APPROVED: `${App.ONBOARDING}/credit-builder-not-approved`,
  WHATS_YOUR_INCOME: `${App.ONBOARDING}/whats-your-income`,
  VERIFY_YOUR_INCOME: `${App.ONBOARDING}/verify-your-income`,
  INCOME_VERIFICATION_WAITING_OFFER: `${App.ONBOARDING}/income-waiting-offer`,
  INCOME_VERIFICATION_PROCESSING_OFFER: `${App.ONBOARDING}/in-review`,
  UPLOAD_PAYSTUBS: `${App.ONBOARDING}/upload-paystubs`,
  DOCUMENT_VERIFICATION: `${App.ONBOARDING}/document-verification`,
}

export const Embed = {
  CONGRATS: `${App.EMBED}/congrats`,
  SIMPLIFIED_EPISODIC: `${App.EMBED}/simplified-episodic`,
  MANUAL_PAY_FINISH: `${App.EMBED}/manual-pay-finish`,
  ALREADY_FLEXING_YOUR_RENT: `${App.EMBED}/already-flexing-your-rent`,
  BUILD_CREDIT_IN_APP: `${App.EMBED}/build-credit-in-app`,
  NOT_RENT_DAY: `${App.EMBED}/not-rent-day`,
  RENT_SPLIT_PROGRESS: `${App.EMBED}/rent-split-progress`,
  SERVICE_ISSUE: `${App.EMBED}/service-issue`,
  SOMETHING_WENT_WRONG: `${App.EMBED}/something-went-wrong`,
  PROPERTY_MISMATCH: `${App.EMBED}/property-mismatch`,
  RELINK_DIRECT_INTEGRATION_CONFIRMATION: `${App.EMBED}/relink-direct-integration-confirm`,
  RELINK_RENT_AMOUNT: `${App.EMBED}/relink-rent-amount`,
  RELINK_OFFER_DETAILS: `${App.EMBED}/relink-offer-details`,
  RELINK_VERIFY_IDENTITY: `${App.EMBED}/relink-verify-identity`,
  CREDIT_LINE_DECLINED: `${App.EMBED}/credit-line-declined`,
  CREDIT_LINE_SUSPENDED: `${App.EMBED}/credit-line-suspended`,
  START_PAYMENT: `${App.EMBED}/start-payment`,
  CHANGE_CARD: `${App.EMBED}/change-card`,
}

export const EmbedLanding = {
  VERIFY: `${App.EMBED_LANDING}`,
  SIGNUP: `${App.EMBED_LANDING}/signup`,
  ALREADY_FLEXING_YOUR_RENT: `${App.EMBED_LANDING}/already-flexing-your-rent`,
  BUILD_CREDIT_IN_APP: `${App.EMBED_LANDING}/build-credit-in-app`,
  NOT_RENT_DAY: `${App.EMBED_LANDING}/not-rent-day`,
  RENT_SPLIT_PROGRESS: `${App.EMBED_LANDING}/rent-split-progress`,
  SERVICE_ISSUE: `${App.EMBED_LANDING}/service-issue`,
  SOMETHING_WENT_WRONG: `${App.EMBED_LANDING}/something-went-wrong`,
  PROPERTY_MISMATCH: `${App.EMBED_LANDING}/property-mismatch`,
  CREDIT_LINE_DECLINED: `${App.EMBED_LANDING}/credit-line-declined`,
  CREDIT_LINE_SUSPENDED: `${App.EMBED_LANDING}/credit-line-suspended`,
  START_PAYMENT: `${App.EMBED_LANDING}/start-payment`,
}
